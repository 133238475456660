import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/layout";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import "../scss/styles.scss";
import errorImg from "../images/404.png";

const NotFoundPage = ({ data }) => {
  const siteName = data.site.siteMetadata.name;

  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: `en` }}>
        <meta charSet="utf-8" />
        <title>404 | {siteName}</title>
      </Helmet>
      <div className="pb-5">
        <Container>
          <Row>
            <Col>
              <img className="img-fluid" alt="404 graphic" src={errorImg} />
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <a
                href="/"
                className="btn btn-primary"
                aria-label="Go to the homepage"
              >
                Home
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export const error404Query = graphql`
  query Error404Query {
    site {
      siteMetadata {
        name
      }
    }
  }
`;

export default NotFoundPage;
